import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { LoaderState } from "kui-crm"
import { FormProvider } from "react-hook-form"
import useRCStore from "../../store"
import RCInfoEditableFields from "../RCInfoEditableFields"
import { RCInfoFieldsProps } from "./types"
import ChangesTable from "../../../../components/common/ChangesTable"
import DocumentsBlockWithActions from "../../../../components/common/DocumentsBlockWithActions"

function RCInfoFields({ form }: RCInfoFieldsProps) {
  const { contractInfoStore, documentsStore, contractChangesStore, editor } =
    useRCStore()
  const contractParams = contractInfoStore.id ? [contractInfoStore.id] : []

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <FormProvider {...form}>
          <ChangesTable
            variant="pending"
            changes={contractChangesStore.pendingChangesLines}
            contractId={contractInfoStore.id}
            contractEditor={editor}
          />
        </FormProvider>
        <RCInfoEditableFields form={form} />
      </Grid>

      <StyledDocumentWrapper item xs={4}>
        <DocumentsBlockWithActions
          documentsStore={documentsStore}
          requestParams={contractParams}
        />
      </StyledDocumentWrapper>

      <LoaderState loader={contractInfoStore.loader} />
    </Grid>
  )
}

export default observer(RCInfoFields)

const StyledDocumentWrapper = styled(Grid)`
  padding-top: 48px !important;
`
