import { addToArrayByCondition } from "kui-utils"
import { InputParams, LinkField, InspectionTypes } from "kui-crm"
import {
  ApartmentWithRentalInspectionValues,
  ApartmentWithRentalTransferValues,
  ApartmentWithServiceInspectionValues,
  ApartmentWithServiceTransferValues,
  ClientRoleValues,
  NewApartmentInspectionValues,
} from "../../../../../utils/content/listsOptions"

const getInspectionTypesOptions = (fields?: any) => {
  if (!fields?.hasServiceContract) return NewApartmentInspectionValues

  if (fields?.hasServiceContract && !fields?.hasRentalContract)
    return ApartmentWithServiceInspectionValues
  if (fields?.hasServiceContract && fields?.hasRentalContract)
    return ApartmentWithRentalInspectionValues

  return []
}

const getTransferTypesOptions = (fields?: any) => {
  if (fields?.hasServiceContract && !fields?.hasRentalContract)
    return ApartmentWithServiceTransferValues
  if (fields?.hasServiceContract && fields?.hasRentalContract)
    return ApartmentWithRentalTransferValues

  return []
}

const getInspectionCommonStepFields = (
  withType?: boolean,
  type?: InspectionTypes,
  fields?: any
): InputParams<any>[] => [
  { label: "Inspection date", name: "date", variant: "date", isRequired: true },
  ...addToArrayByCondition(withType, {
    label: "Inspection type",
    name: "type",
    variant: "select",
    options: getInspectionTypesOptions(fields),
    isRequired: true,
  }),
  ...addToArrayByCondition(type === "transfer", {
    label: "Transfer type",
    name: "transferType",
    variant: "select",
    options: getTransferTypesOptions(fields),
    isRequired: true,
  }),
  ...addToArrayByCondition(type === "appraisal" && fields?.basedOn, {
    label: "Client",
    name: "clientRole",
    variant: "select",
    options: ClientRoleValues,
    disabled: !fields?.hasRentalContract,
    value: fields?.hasRentalContract ? undefined : "landlord",
    isRequired: true,
  }),
  {
    label: "Dropbox link",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default getInspectionCommonStepFields
