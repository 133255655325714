import _ from "lodash"
import { RentalContractModel, RentalTypes, ContractStatuses } from "kui-crm"
import ApartmentSimpleStore from "./Apartment"
import UserLiteStore from "./UserLite"

class RentalContractLiteStore {
  id: number

  apartment: ApartmentSimpleStore | null

  number: string

  landlord: UserLiteStore

  tenant: UserLiteStore | null

  status: ContractStatuses

  rentCost: number

  deposit: number

  commission: number

  type: RentalTypes

  constructor(contract: RentalContractModel) {
    this.id = contract.id
    this.apartment = contract.apartment
      ? new ApartmentSimpleStore(contract.apartment)
      : null
    this.number = contract.number
    this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    this.tenant = contract.renter
      ? UserLiteStore.initFromLiteUserModel(contract.renter)
      : null
    this.rentCost = Number(contract.month_payment)
    this.deposit = Number(contract.security_payment)
    this.commission = Number(contract.initial_commission)
    this.type = contract.rental_type
    this.status = contract.status === "Closed" ? "Closed" : "Open"
  }

  get formattedType() {
    return this.type ? _.capitalize(this.type) : "-"
  }
}

export default RentalContractLiteStore
