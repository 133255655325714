import React, { useEffect, useState } from "react"
import { Path, PathValue, useFieldArray } from "react-hook-form"
import { UploadFilesWithSettingProps } from "./types"
import DocumentSettings from "../DocumentSettings"
import UploadFilesForm from "../../ui/UploadFilesForm"
import { UploadFilesFields } from "../../ui/UploadFilesForm/types"

function UploadFilesWithSetting(props: UploadFilesWithSettingProps) {
  const { contractType, form, handleSubmit, ...otherProps } = props
  const [selectedFile, setSelectedFile] = useState<number | null>(null)
  const [settingsLength, setSettingsLength] = useState(0)
  const { append, remove } = useFieldArray<any>({
    control: form.control,
    name: "settings",
  })

  const defaultSettings = {
    landlord: false,
    tenant: false,
    contractType,
  }

  const addFiles = (files: any[]) => {
    files.forEach((_, index) => {
      const value = form.getValues(
        `settings.${index + settingsLength}` as Path<any>
      )
      if (value) {
        form.setValue(
          `settings.${index + settingsLength}` as Path<any>,
          defaultSettings as PathValue<any, any>
        )
      } else {
        append(defaultSettings)
      }
      setSettingsLength((prev) => prev + 1)
    })
  }

  const removeFile = (index: number) => {
    setSettingsLength((prev) => prev - 1)
    remove(index)
  }

  useEffect(() => {
    form.setValue(
      "globalSettings" as Path<any>,
      defaultSettings as PathValue<any, any>
    )
  }, [])

  return (
    <UploadFilesForm
      form={form}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      onAdd={addFiles}
      onRemove={removeFile}
      settings={
        <DocumentSettings
          selected={selectedFile}
          contractType={contractType}
          form={form}
        />
      }
      handleSubmit={handleSubmit as (data: UploadFilesFields) => void}
      {...otherProps}
    />
  )
}

export default UploadFilesWithSetting
