import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { ApartmentExpensesPeriodModel } from "../types/api/expensesPeriodAPI"
import ApartmentExpensesAgent from "../../../../../agent/ApartmentExpenses"
import ApartmentExpensesStore from "./ApartmentExpenses"
import ApartmentExpensesPeriod from "./ApartmentExpensesPeriod"

class ApartmentExpensesPeriods {
  expensesStore: ApartmentExpensesStore

  periods: ApartmentExpensesPeriod[]

  selectedPeriod: ApartmentExpensesPeriod | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.periods = []
    this.selectedPeriod = null
    this.expensesStore = expensesStore
    makeAutoObservable(this)
  }

  getPeriods = async (apartmentId: number, date: DateTime) => {
    this.expensesStore.loader.startLoading()
    this.cleanPeriods()

    const [err, res] = await to<ApartmentExpensesPeriodModel[]>(
      ApartmentExpensesAgent.getPeriods(apartmentId, date.year, date.month)
    )

    if (res && !err) {
      this.updatePeriodInfo(res)
    } else {
      this.expensesStore.loader.setError("fetch period status", err)
    }
    this.expensesStore.loader.endLoading()
  }

  updatePeriodInfo = (res: ApartmentExpensesPeriodModel[]) => {
    this.periods = res.map(
      (period) => new ApartmentExpensesPeriod(period, this.expensesStore)
    )
    this.selectedPeriod = this.periods[this.periods.length - 1] || null
  }

  setSelectedPeriod = (id: number) => {
    this.selectedPeriod =
      this.periods.find((period) => period.id === id) || null
  }

  cleanPeriods = () => {
    this.periods = []
    this.selectedPeriod = null
  }

  get availablePeriodsOptions() {
    return this.periods.map((period) => ({
      contractId: period.tenant ? period.contractInfo?.id : 0,
      number: period.contractInfo?.number,
      label: `${period.tenant ? "" : "Service "}${
        period.contractInfo?.number
      } of ${period.contractInfo?.date?.toFormat("dd.MM.yy")}`,
      value: period.id,
    }))
  }
}

export default ApartmentExpensesPeriods
