import React from "react"
import { observer } from "mobx-react"
import { DateTime } from "luxon"
import _ from "lodash"
import { updateQueryParams } from "kui-utils"
import CalendarWithMonthButton from "../../../../../../../components/common/CalendarWithMonthButton"
import useExpensesStore from "../../../store"
import CheckoutDateWarning from "../CheckoutDateWarning"

function ExpensesCalendar() {
  const { setDate, date, overviewStore, periodsStore } = useExpensesStore()
  const diffNow = Number(date?.diffNow("months").months)
  const { selectedPeriod } = periodsStore
  const showNextMonth =
    diffNow > 0 || (selectedPeriod?.isClosed && diffNow > -1)

  const handleChangeDate = (newDate: Date) => {
    const formattedDate = DateTime.fromJSDate(newDate)
    setDate(formattedDate)

    updateQueryParams(`&date=${formattedDate.toFormat("MM.yy")}`)
  }

  return (
    <CalendarWithMonthButton
      initialDate={date?.toJSDate() || new Date()}
      minDate={overviewStore.startDate}
      maxDate={showNextMonth && DateTime.now().plus({ month: 1 })}
      handleChange={handleChangeDate}
      title={_.capitalize(date?.toFormat("LLL yyyy"))}
      endIcon={<CheckoutDateWarning />}
    />
  )
}

export default observer(ExpensesCalendar)
