import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import { DateTime } from "luxon"
import { Caption, Box, Grid } from "kui-basic"
import { RadioGroupWithLabel, InputWithController } from "kui-complex"
import UserAutocomplete from "../../../../../components/entityInputs/ClientAutocomplete"
import CheckboxWithController from "../../../../../components/ui/CheckboxWithController"
import InputWithDatepicker from "../../../../../components/ui/InputWithDatepicker"
import {
  PeriodValues,
  RentalTypesValues,
} from "../../../../../utils/content/listsOptions"
import { BasicConditionsFieldsProps } from "./types"
import FieldsGroupTitle from "../../../../../components/common/FieldsGroupTitle"
import useRCsStore from "../../../store"
import { RentalContractTypes } from "../../../../../types/store/contract"
import { ClientLiteParams } from "../../../../../types/store/user"

function BasicConditionsFields({ form }: BasicConditionsFieldsProps) {
  const { creationForm } = useRCsStore()
  const { fields, updateFormFields } = creationForm

  const setEndDate = (
    contractType: RentalContractTypes,
    startDate: DateTime
  ) => {
    if (contractType === "fixed") {
      const endDate = startDate
        .plus({ year: 1 })
        .minus({ month: 1 })
        .endOf("month")
      form.setValue("endDate", endDate)
    }
  }

  const handleNumberChange = () => {
    const numberError = form.formState.errors.number
    if (numberError) {
      form.clearErrors("number")
    }
  }

  const handleStartDateChange = (date: DateTime | null) => {
    const contractType = form.getValues("contractType") as RentalContractTypes
    if (date) setEndDate(contractType, date)
  }

  const handleSignDateChange = (date: DateTime | null) => {
    const startDate = fields?.serviceContractStartDate
    if (startDate && date) {
      const isOlderSCStartDate =
        date.diff(startDate, "days").toObject().days! >= 0
      if (isOlderSCStartDate) {
        form.clearErrors("signDate")
      } else {
        form.setError("signDate", {
          message: "Должно быть больше start date сервисного контракта",
        })
      }
    }
  }

  const handleContractTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const contractType = e.target.defaultValue as RentalContractTypes
    const startDateValue = form.getValues("startDate")
    const startDate = startDateValue || null
    if (startDate) setEndDate(contractType, startDate)
    if (contractType === "openEnded") form.resetField("endDate")

    updateFormFields({ contractType })
  }

  const handleTenantChange = (client?: ClientLiteParams | null) => {
    if (client) form.setValue("clientType", client?.clientType)
  }

  return (
    <Box pr={5} pl={5} mb={2}>
      <FieldsGroupTitle title="BASIC CONDITIONS" />

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <InputWithController
            data-testid="number"
            label="Number of contract"
            name="number"
            form={form}
            onChange={handleNumberChange}
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutocomplete
            testId="userAutocomplete"
            form={form}
            label="Tenant"
            name="tenant"
            searchFilter={`&exclude_owner_for_apartment=${fields?.apartment?.id}`}
            onSelect={handleTenantChange}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupWithLabel
            testId="rentalType"
            value={fields?.rentalType}
            title="Rental type"
            form={form}
            options={RentalTypesValues}
            name="rentalType"
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithDatepicker
            testId="signDate"
            label="Sign date"
            name="signDate"
            form={form}
            onChange={handleSignDateChange}
            max={DateTime.now()}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithDatepicker
            testId="startDate"
            label="Start date"
            name="startDate"
            form={form}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupWithLabel
            testId="contractType"
            value={fields?.contractType}
            title="Contract type"
            form={form}
            options={PeriodValues}
            name="contractType"
            onChange={handleContractTypeChange}
          />
        </Grid>
        {fields?.contractType === "fixed" && (
          <>
            <Grid item xs={6}>
              <InputWithDatepicker
                testId="endDate"
                label="End date"
                name="endDate"
                form={form}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckboxWithController
                form={form}
                name="autoProlongation"
                label={<Caption>Auto prolongation</Caption>}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default observer(BasicConditionsFields)
