import React from "react"
import { observer } from "mobx-react"
import ContractEditingButtons from "../../../../components/common/ContractEditingButtons"
import useRCStore from "../../store"
import { RCFieldsHeaderProps } from "../RCFieldsHeader/types"
import { RCInfoFormFields } from "../RCInfoFields/types"

const RCEditingButtons = ({ form }: RCFieldsHeaderProps) => {
  const { editor, addPendingChange, contractChangesStore, contractInfoStore } =
    useRCStore()

  const handleEdit = (data: RCInfoFormFields) => {
    const scStartDate = contractInfoStore.serviceContract?.startDate
    const isOlderStartDate =
      scStartDate &&
      data.signDate?.diff(scStartDate, "days").toObject().days! >= 0

    if (isOlderStartDate) {
      if (contractInfoStore.status === "Pending")
        contractInfoStore.patchRentalContractInfo(data)
      else addPendingChange(data)

      form.clearErrors("signDate")
      editor.endEditing()
    } else {
      form.setError("signDate", {
        message: "Must be more than the start date of the service contract",
      })
    }
    if (contractInfoStore.status !== "Pending") form.reset()
  }

  return (
    <ContractEditingButtons
      editor={editor}
      form={form}
      handleEdit={handleEdit}
      handleActivate={contractInfoStore.activateRentalContract}
      status={contractInfoStore.status}
      contractChangesStore={contractChangesStore}
    />
  )
}

export default observer(RCEditingButtons)
