import React, { useState } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Heading, Button, Box, Grid } from "kui-basic"
import { LoaderState } from "kui-crm"
import { PlusIcon } from "kui-icon"
import PaymentCreationForm from "../../../../forms/summary/PaymentCreationForm"
import ExpensesPaymentLine from "../ExpensesPaymentLine"
import { PaymentTableStylesProps } from "./types"
import Container from "../../../../../../../../components/ui/Container"
import useExpensesStore from "../../../../store"
import { NewPaymentFormInputs } from "../../../../forms/summary/PaymentCreationForm/types"

function ExpensesPayments() {
  const { overviewStore, summaryStore, periodsStore } =
    useExpensesStore()
  const { paymentsStore } = summaryStore
  const [newPaymentRows, setNewPaymentRows] = useState<number[]>([])
  const { selectedPeriod } = periodsStore

  const addNewPaymentRows = () => {
    setNewPaymentRows((prev: number[]) => [...prev, prev.length])
  }

  const deleteNewPaymentRow = (item: number) => {
    setNewPaymentRows((prev) => prev.filter((elem) => elem !== item))
  }

  const handleSubmit = async (data: NewPaymentFormInputs, item: number) => {
    deleteNewPaymentRow(item)
    if (overviewStore.id) {
      await paymentsStore.addPayment(
        data,
        overviewStore.id,
        selectedPeriod?.rentalContract?.id
      )
    }
  }

  return (
    <StyledContainer>
      <StyledWrapper container justify="space-between" alignItems="center">
        <Heading size="h3">Payments</Heading>
        <StyledButtonWrapper item>
          <Button
            isCircle
            data-testid="add_payment_btn"
            variant="whiteWithGray"
            size="xs"
            onClick={addNewPaymentRows}
            disabled={!selectedPeriod?.canBeEdited}
          >
            <PlusIcon width={10} height={10} />
          </Button>
        </StyledButtonWrapper>
      </StyledWrapper>
      <Box mt={2}>
        <StyledPaymentFormsTable
          hasPaymentForm={newPaymentRows.length > 0}
          data-testid="payment_forms_table"
        >
          {newPaymentRows.map((item) => (
            <PaymentCreationForm
              key={item}
              handleSubmit={(data: any) => handleSubmit(data, item)}
              handleClick={() => deleteNewPaymentRow(item)}
            />
          ))}
        </StyledPaymentFormsTable>
        <StyledPaymentsTable
          hasPaymentForm={newPaymentRows.length > 0}
          data-testid="payment_lines_table"
        >
          {paymentsStore?.paymentsLines.map((payment) => (
            <ExpensesPaymentLine key={payment.id} payment={payment} />
          ))}
        </StyledPaymentsTable>
      </Box>

      <LoaderState loader={paymentsStore.actionLoader} />
    </StyledContainer>
  )
}

export default observer(ExpensesPayments)

const StyledButtonWrapper = styled(Grid)`
  padding-right: 15px;
`

const StyledContainer = styled(Container)`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.background.light1};
  padding: 16px 8px 8px !important;
`

const StyledPaymentsTable = styled.div<PaymentTableStylesProps>`
  background: ${({ theme }) => theme.palette.grey.zero};
  border-radius: ${({ hasPaymentForm }) =>
    hasPaymentForm ? "0 0 12px 12px" : "12px"};
`

const StyledPaymentFormsTable = styled.div<PaymentTableStylesProps>`
  background: ${({ theme }) => theme.palette.grey.zero};
  border-radius: ${({ hasPaymentForm }) =>
    hasPaymentForm ? "12px 12px 0 0" : "12px"};
  margin-bottom: 2px;
  form:first-of-type {
    .TransactionSelect input {
      border-radius: 12px 0 0 0 !important;
    }
  }
  form:last-of-type {
    .TransactionSelect input {
      border-radius: ${({ hasPaymentForm }) =>
        hasPaymentForm ? "0" : "0 12px 0 0"} !important;
    }
  }
`

const StyledWrapper = styled(Grid)`
  padding-left: 16px;
`
