import React from "react"
import { WarningIcon } from "kui-icon"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { Tooltip } from "kui-crm"
import useExpensesStore from "../../../store"
import { CheckoutDateWarningStylesProps } from "./types"

const CheckoutDateWarning = () => {
  const { date, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const checkoutDate = selectedPeriod?.checkoutDate

  if (!checkoutDate || !date) return null

  const isCurrentMonth =
    Math.floor(checkoutDate.diff(date, "months").months) === 0

  return (
    <StyledTooltip
      placement="bottom-end"
      offsetOptions={{ crossAxis: 16 }}
      content={
        <StyledLabel size="xs" weight={500}>
          {isCurrentMonth
            ? "This is the last month of the contract"
            : `Check-out date: ${checkoutDate.toFormat("dd.MM.yyyy")}`}
        </StyledLabel>
      }
    >
      <StyledWarningIcon isCurrentMonth={isCurrentMonth} />
    </StyledTooltip>
  )
}

export default CheckoutDateWarning

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper {
    max-width: 200px !important;
  }
  p {
    color: ${({ theme }) => theme.palette.grey.seventy} !important;
  }
`

const StyledLabel = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey.seventy} !important;
`

const StyledWarningIcon = styled(WarningIcon)<CheckoutDateWarningStylesProps>`
  margin-left: 2px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  cursor: pointer;
  path {
    fill: ${({ theme, isCurrentMonth }) =>
      isCurrentMonth
        ? theme.palette.red.seventy
        : theme.palette.brand.main} !important;
  }
`
