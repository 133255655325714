import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { Editor } from "kui-utils"
import {
  FileBodyRequest,
  RentalContractModel,
  ServiceContractModel,
} from "kui-crm/types"
import FileStore from "./File"
import { ChangeParams } from "../../components/common/ChangesTable/types"
import {
  ContractAPIFields,
  ContractChangeModel,
  ContractChangesFields,
  ContractsChangeGroupModel,
} from "../../types/api/contracts"
import getSCChangeRowParams from "../../utils/content/scChangesLines"
import getRCChangeRowParams from "../../utils/content/rcChangesLines"
import { ContractTypes } from "../../types/common"
import { SCChangesFields } from "../../types/api/service_contracts"
import { RCChangesFields } from "../../types/api/rental_contract"
import ContractChangesStore from "./ContractChangesStore"
import ServiceContractInfoStore from "../shared/contracts/ServiceContractInfoStore"
import RentalContractInfoStore from "../shared/contracts/RentalContractInfoStore"
import { uploadNewFile } from "../../utils/agent/uploadFiles"
import { ContractFields } from "../../types/store/contract"

class ContractChangeGroupStore {
  id: number

  dateOfChange: DateTime | null

  file: FileStore | null

  changes: ChangeParams[]

  changesFields: ContractFields

  createdDate: DateTime | null

  createdBy: string

  editor: Editor

  changesStore: ContractChangesStore

  variant: ContractTypes

  constructor(
    changeGroup: ContractsChangeGroupModel,
    variant: ContractTypes,
    changesStore: ContractChangesStore
  ) {
    this.changesStore = changesStore
    this.editor = new Editor()
    this.variant = variant

    this.id = changeGroup.id
    this.dateOfChange = changeGroup.apply_from
      ? DateTime.fromISO(changeGroup.apply_from)
      : null
    this.file = changeGroup.reason_document
      ? FileStore.initFromDocumentModel(changeGroup.reason_document)
      : null
    this.createdDate = changeGroup.created
      ? DateTime.fromISO(changeGroup.created)
      : null
    this.createdBy = changeGroup.created_by?.full_name || "Unknown"
    this.changes = ContractChangeGroupStore.getChangesParams(
      variant,
      changeGroup.changes
    )
    this.changesFields = ContractChangeGroupStore.getChangesFields(
      variant,
      changeGroup.changes
    )
    makeAutoObservable(this)
  }

  deletePendingChange = async (contractId: number) => {
    this.changesStore.loader.startLoading("pending change removal")

    const [err] = await to(
      this.changesStore.agent.deleteChange(contractId, this.id)
    )

    runInAction(() => {
      if (!err) {
        this.changesStore.deletePendingFromList(this.id)
      } else {
        this.changesStore.loader.setError("pending change removal", err)
      }
      this.changesStore.loader.endLoading()
    })
  }

  editPendingChange = async (contractId: number, data: any) => {
    const { contractInfoStore, loader, agent } = this.changesStore
    const file = await uploadNewFile(loader, data.changesFile)
    const body = ContractChangeGroupStore.getPatchChangeBody(
      this.variant,
      data,
      file,
      contractInfoStore
    )

    const [err, res] = await to<ContractsChangeGroupModel>(
      agent.editChange(contractId, this.id, body)
    )
    if (!err && res) {
      this.updatePendingChange(res.changes)
    } else {
      loader.setError(`patch pending change`, err)
    }
  }

  updatePendingChange = (changes: ContractChangesFields) => {
    this.changes = ContractChangeGroupStore.getChangesParams(
      this.variant,
      changes
    )
  }

  static getChangesParams = (
    variant: ContractTypes,
    changes: ContractChangesFields
  ) => {
    if (variant === "service") {
      return Object.keys(changes).map((changeField) =>
        ContractChangeGroupStore.getSCChange(
          changes[changeField as keyof SCChangesFields],
          changeField
        )
      )
    }
    return Object.keys(changes).map((changeField) =>
      ContractChangeGroupStore.getRCChange(
        (changes as RCChangesFields)[changeField as keyof RCChangesFields],
        changeField
      )
    )
  }

  static getChangesFields = (
    variant: ContractTypes,
    changes: ContractChangesFields
  ): ContractFields => {
    const contractAPIFields: ContractAPIFields = {}
    Object.keys(changes).forEach((changeKey) => {
      contractAPIFields[changeKey as keyof ContractAPIFields] =
        changes[changeKey as keyof typeof changes]?.new
    })

    const utilityPayers = [
      contractAPIFields.utility_payer,
      contractAPIFields.water_payer,
      contractAPIFields.electricity_payer,
      contractAPIFields.gas_payer,
      contractAPIFields.heating_payer,
    ].filter((payer) => payer)

    const formattedFields = {
      ...contractAPIFields,
      utility_payers: utilityPayers,
    }
    if (variant === "service")
      return ServiceContractInfoStore.getContractFields(
        formattedFields as Partial<ServiceContractModel>
      )
    return RentalContractInfoStore.getContractFields(
      formattedFields as Partial<RentalContractModel>
    )
  }

  static getSCChange = (change: ContractChangeModel<any>, name: string) =>
    getSCChangeRowParams({
      fieldName: name,
      currentValue: change.old,
      newValue: change.new,
    })

  static getRCChange = (change: ContractChangeModel<any>, name: string) =>
    getRCChangeRowParams({
      fieldName: name,
      currentValue: change.old,
      newValue: change.new,
    })

  static getPatchChangeBody = (
    variant: ContractTypes,
    data: any,
    file: FileBodyRequest | null,
    contractInfo: RentalContractInfoStore | ServiceContractInfoStore
  ) => ({
    reason_document: file,
    changes:
      variant === "rental"
        ? RentalContractInfoStore.getPatchRCFields(data)
        : ServiceContractInfoStore.getPatchSCFields(
            data,
            contractInfo as ServiceContractInfoStore
          ),
  })
}

export default ContractChangeGroupStore
