import React from "react"
import { Modal } from "kui-crm"
import { EditingChangeModalProps } from "./types"
import { EditingChangeFormFields } from "../EditingChangeForm/types"
import EditingChangeForm from "../EditingChangeForm"

const EditingChangeModal = (props: EditingChangeModalProps) => {
  const { changesGroup, open, handleClose, contractEditor, form } = props

  const handleSubmit = (data: EditingChangeFormFields) => {
    form.setValue("changesFile", data.document)
    const fields = changesGroup.changesFields
    console.log("fields", fields)
    Object.keys(fields).forEach((changeKey) => {
      const value = fields[changeKey as keyof typeof fields]

      if (value !== null && typeof value !== "undefined") {
        form.setValue(changeKey, value)
      }
    })
    changesGroup.editor.startEditing()
    contractEditor?.startEditing()
    handleClose()
  }

  return (
    <Modal
      title="Editing of pending change"
      open={open}
      handleClose={handleClose}
      data-testid="editingModal"
    >
      <EditingChangeForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default EditingChangeModal
