import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { LoaderState } from "kui-crm"
import { LockIcon } from "kui-icon"
import TwoCaptionsWithImageBlock from "../../../../../../../../components/common/TwoCaptionsWithImageBlock"
import UndefinedAvatar from "../../../../../../../../components/common/UndefinedAvatar"
import ClosePeriodModal from "../ClosePeriodModal"
import TooltipWithArrow from "../../../../../../../../components/ui/TooltipWithArrow"
import useExpensesStore from "../../../../store"
import { defaultUserName } from "../../../../../../../../utils/content/constants"
import PopupWithCheckList from "../../../../../../../../components/common/PopupWithCheckList"

function ClosePeriodButton() {
  const { periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const userName = selectedPeriod?.closedBy?.partialName || defaultUserName
  const canBeClosed = selectedPeriod?.canBeClosed
  const closingProgress = selectedPeriod?.closingProgress

  return (
    <Grid item>
      {selectedPeriod?.isClosed ? (
        <TooltipWithArrow
          content={
            <TwoCaptionsWithImageBlock
              image={<UndefinedAvatar label={userName} />}
              top={String(userName)}
              bottom={selectedPeriod?.formattedClosingDate ?? ""}
              topWeight={500}
              topSize="s"
              bottomSize="xs"
              topTextColor="zero"
              spacing={2}
            />
          }
        >
          <span>
            <StyledClosedButton
              startIcon={<LockIcon />}
              size="s"
              variant="backgroundWithBorder"
              data-testid="closed_period_btn"
            >
              Closed
            </StyledClosedButton>
          </span>
        </TooltipWithArrow>
      ) : (
        <PopupWithCheckList
          label="To close a period you need to:"
          items={closingProgress || []}
          popupActive={!canBeClosed}
          button={
            <StyledButton
              onClick={handleModalOpen}
              startIcon={<LockIcon />}
              size="s"
              variant="white"
              data-testid="open_period_btn"
              disabled={!canBeClosed}
            >
              Close period
            </StyledButton>
          }
        />
      )}

      <LoaderState loader={selectedPeriod?.actionLoader} />
      <ClosePeriodModal open={isModalOpen} handleClose={handleModalClose} />
    </Grid>
  )
}

export default observer(ClosePeriodButton)

const StyledButton = styled(Button)`
  &:not(:disabled) p {
    color: ${({ theme }) => theme.palette.grey.seventy};
  }
  &:disabled {
    border-color: transparent;
  }
`

const StyledClosedButton = styled(Button)`
  pointer-events: none;
`
