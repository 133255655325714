import React from "react"
import { useToggle } from "kui-utils"
import { Button, Grid } from "kui-basic"
import { CheckWithCircleIcon } from "kui-icon"
import ActivateContractModal from "../../modals/ActivateContractModal"
import { ActivateContractButtonProps } from "./types"

const ActivateContractButton = (props: ActivateContractButtonProps) => {
  const { handleSubmit, status } = props
  const [isModalOpen, openModal, closeModal] = useToggle()

  if (status !== "Pending") return null

  return (
    <>
      <Grid item>
        <Button
          data-testid="activateButton"
          size="xs"
          startIcon={<CheckWithCircleIcon />}
          onClick={openModal}
        >
          Activate
        </Button>
      </Grid>
      <ActivateContractModal
        open={isModalOpen}
        handleClose={closeModal}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default ActivateContractButton
