import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import {
  FileWithVisibility,
  InputFileWithVisibilityFields,
  InspectionRoomPhotoModel,
  InspectionTypes,
  matchesAPIVisibilityVariants,
  PatchFileParams,
} from "kui-crm"

import { InspectionInfoInterface } from "../types/store/inspection"
import AppraisalInspectionStore from "./variations/AppraisalInspectionStore"
import ImpairmentsInspectionStore from "./variations/ImpairmentsInspectionStore"
import {
  InspectionBasedOnParams,
  InspectionModel,
  PatchInspectionFileParams,
  PatchRoomImagesParams,
} from "../types/api/common"
import ImageStore from "../../../../../store/templates/ImageStore"
import ApartmentPageStore from "../../../store/ApartmentPageStore"
import InitialInspectionStore from "./variations/InitialInspectionStore"
import TransferInspectionStore from "./variations/TransferInspectionStore"
import RegularInspectionStore from "./variations/RegularInspectionStore"
import {
  uploadFiles,
  uploadImages,
} from "../../../../../utils/agent/uploadFiles"
import ApartmentInspectionAgent from "../../../../../agent/ApartmentInspection"
import InventoryInspectionStore from "./variations/InventoryInspectionStore"
import { InspectionRoomFields } from "../components/InspectionRoomsPhoto/types"
import { PhotoCardParams } from "../../../../../components/common/PhotoCard/types"
import FileStore from "../../../../../store/templates/File"
import { UploadFileParams } from "../../../../../components/common/FileItem/types"
import { InspectionSignParams } from "../../../types/store/apartmentInspections"
import ApartmentInspectionStore from "../../../store/inspections/ApartmentInspectionStore"
import { InspectionEditConditionReasons } from "../../../../../utils/content/values"
import RoomImagesStore from "./RoomImagesStore"

const InspectionByType = {
  initial: InitialInspectionStore,
  inventory: InventoryInspectionStore,
  transfer: TransferInspectionStore,
  regular: RegularInspectionStore,
  appraisal: AppraisalInspectionStore,
  impairments: ImpairmentsInspectionStore,
}

class InspectionPageStore {
  type: InspectionTypes | null = null

  inspectionInfo: InspectionInfoInterface | null = null

  loader: Loader

  actionLoader: Loader

  apartmentStore: ApartmentPageStore

  landlordFeedback: InspectionSignParams | null

  tenantFeedback: InspectionSignParams | null

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.landlordFeedback = null
    this.tenantFeedback = null
    makeAutoObservable(this)
  }

  fetchInspection = async (id: number) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.loader.startLoading("inspection")

      const response = await to<InspectionModel>(
        ApartmentInspectionAgent.getById(apartmentId, id)
      )

      resHandler(response, this.loader, this.setInspectionByType)
    }
  }

  patchInspection = async (data: any) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId && this.type && this.inspectionInfo) {
      this.actionLoader.startLoading()

      const body = await this.inspectionInfo.patchInspection(data)

      const response = await to<InspectionModel>(
        ApartmentInspectionAgent.patch(
          apartmentId,
          this.inspectionInfo.id,
          body
        )
      )

      resHandler(response, this.actionLoader, this.setInspectionByType)
    }
    this.editor.endEditing()
  }

  deleteInspection = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId && this.inspectionInfo) {
      this.actionLoader.startLoading()

      const response = await to<InspectionModel>(
        ApartmentInspectionAgent.delete(apartmentId, this.inspectionInfo.id)
      )

      resHandler(response, this.loader)
    }
  }

  resignInspection = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    const inspectionId = this.inspectionInfo?.id

    if (apartmentId && inspectionId) {
      this.actionLoader.startLoading()

      const response = await to(
        ApartmentInspectionAgent.resign(apartmentId, inspectionId)
      )

      resHandler(response, this.actionLoader, this.updateFeedback)
    }
  }

  updateFeedback = () => {
    this.tenantFeedback = this.tenantFeedback
      ? {
          ...this.tenantFeedback,
          status: "notSigned",
        }
      : null
  }

  actualizeRoomsImages = async (
    rooms: InspectionRoomFields[]
  ): Promise<PatchRoomImagesParams[]> => {
    const images = rooms.map((room) => room.images)
    const formattedImages = await this.actualizeImages(images)
    return rooms.map((room, index) => ({
      id: room.id,
      type: room.type,
      comment: room.comment,
      photos: formattedImages[index],
    }))
  }

  actualizeImages = async (
    imagesBlock: (PhotoCardParams | UploadFileParams | ImageStore)[][]
  ) => {
    const imagesToLoad: (PhotoCardParams | UploadFileParams | ImageStore)[] = []

    imagesBlock.forEach((images) => {
      images.forEach((image) => {
        if ("file" in image && image.file) imagesToLoad.push(image)
      })
    })

    const uploadedImages = await uploadImages(this.actionLoader, imagesToLoad)
    let currentImage = -1
    return imagesBlock.map((images) =>
      images.map((image) => {
        if (
          image.id &&
          (!("file" in image) || ("file" in image && !image.file))
        ) {
          // если фотка уже существовала, то просто отправляем ее Id
          return { id: image.id }
        }
        currentImage += 1
        return uploadedImages[currentImage] // если была загружена новая фотка, то отправляем ее данные
      })
    )
  }

  actualizeFiles = async (
    files: InputFileWithVisibilityFields[]
  ): Promise<PatchInspectionFileParams[]> => {
    const filesToLoad: (FileWithVisibility | File)[] = []

    files.forEach((file) => {
      if (
        file &&
        (("file" in file && file.file instanceof File) || file instanceof File)
      ) {
        filesToLoad.push(file)
      }
    })

    let currentFile = -1
    const uploadedFiles = await uploadFiles(this.actionLoader, filesToLoad)

    return files.map((file) => {
      if (
        file &&
        (("file" in file && file.file instanceof File) || file instanceof File)
      ) {
        currentFile += 1
        return uploadedFiles[currentFile]
      }
      if (file instanceof FileStore) {
        return {
          id: file.id,
          name: file.name,
          visibility: file.visibility
            ? matchesAPIVisibilityVariants[file.visibility]
            : null,
        } as PatchFileParams
      }
      return null
    })
  }

  loadRoomImages = async (id: number) => {}

  setInspectionByType = (inspection: InspectionModel) => {
    this.type = inspection.inspection_type
    this.inspectionInfo = new InspectionByType[inspection.inspection_type](
      inspection as any,
      this,
      this.apartmentStore
    )
    this.landlordFeedback = ApartmentInspectionStore.getFeedbackParams(
      inspection.owner_feedback
    )
    this.tenantFeedback = ApartmentInspectionStore.getFeedbackParams(
      inspection.renter_feedback
    )
    if (inspection.edit_condition && !inspection.edit_condition.can_edit) {
      this.apartmentStore.setDisabledReason(
        InspectionEditConditionReasons[inspection.edit_condition.reason]
      )
    }
  }

  clearInspectionPage = () => {
    this.inspectionInfo = null
    this.type = null
    this.loader.startLoading()
  }

  setDisabledReason = (inspection: InspectionModel) => {
    if (inspection.edit_condition && !inspection.edit_condition.can_edit) {
      const { reason } = inspection.edit_condition
      if (reason) {
        this.apartmentStore.setDisabledReason(
          InspectionEditConditionReasons[reason]
        )
      }
    }
  }

  get editor() {
    return this.apartmentStore.editor
  }

  static getPatchBodyInspectionByType = () => {}

  static getObjectRoomsImages = (
    images: InspectionRoomPhotoModel[],
    inspectionId: number,
    apartmentId: number
  ): RoomImagesStore[] =>
    images.map(
      (imageBlock) => new RoomImagesStore(imageBlock, inspectionId, apartmentId)
    )

  static getBasedOnInspection = (basedOn?: InspectionBasedOnParams | null) => {
    if (basedOn) {
      return {
        id: basedOn.id,
        type: basedOn.inspection_type,
      }
    }
    return null
  }
}

export default InspectionPageStore
