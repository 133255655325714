import React from "react"
import { Grid } from "kui-basic"
import { DateTime } from "luxon"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import EditContractModal from "../../modals/EditContractModal"
import { ContractEditingButtonsProps } from "./types"
import EditButton from "../../ui/EditButton"
import ActivateContractButton from "../ActivateContractButton"

const ContractEditingButtons = (props: ContractEditingButtonsProps) => {
  const { editor, status, contractChangesStore } = props
  const { form, handleEdit, handleActivate } = props
  const [isModalOpen, handleOpen, handleClose] = useToggle()

  const startEditing = () => {
    if (status === "Pending") editor.startEditing()
    else handleOpen()
  }

  return (
    <>
      <Grid item>
        <Grid container spacing={3}>
          <ActivateContractButton
            status={status}
            handleSubmit={handleActivate}
          />

          <Grid item>
            <EditButton
              disabled={contractChangesStore.hasEditingChange}
              isEditing={editor.isEditing}
              onSaveHandler={form.handleSubmit(handleEdit)}
              onEditHandler={startEditing}
            />
          </Grid>
        </Grid>
      </Grid>
      <EditContractModal
        open={isModalOpen}
        handleClose={handleClose}
        editor={editor}
        form={form}
        usedDates={contractChangesStore.usedPendingDates}
        lastOpenPeriod={DateTime.now()}
      />
    </>
  )
}

export default observer(ContractEditingButtons)
